// src/components/PostDetail.js
import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getPost, deletePost } from '../api';
import { useContext } from 'react';
import { PostContext } from '../PostContext';

function PostDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { deletePostFromState } = useContext(PostContext);
  const [post, setPost] = useState(null);

  useEffect(() => {
    async function fetchPost() {
      const data = await getPost(id);
      setPost(data);
    }
    fetchPost();
  }, [id]);

  const handleDelete = async () => {
    await deletePost(id);
    deletePostFromState(id);
    navigate('/posts');
  };

  if (!post) return <div>Cargando...</div>;

  return (
    <div>
      <h2>{post.titulo}</h2>
      <p>{post.contenido}</p>
      {post.imagenes && <img src={post.imagenes} alt={post.titulo} />}
      <p>Autor: {post.nombre}</p>
      <p>Fecha: {post.fecha}</p>
      <Link to={`/posts/${id}/edit`}>Editar</Link>
      <button onClick={handleDelete}>Eliminar</button>
    </div>
  );
}

export default PostDetail;
