// src/components/Navbar.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PostContext } from '../PostContext';

function Navbar() {
  const { posts } = useContext(PostContext);

  const latestPosts = posts.slice(0, 3);

  return (
    <nav className="navbar">
      <h1><Link to="/posts">Mi Blog</Link></h1>
      <div>
        <Link to="/posts/new" className="create-post-button">Crear Nuevo Post</Link>
        <span>Total de Posts: {posts.length}</span>
        <div className="latest-posts">
          <h3>Últimos Posts:</h3>
          <ul>
            {latestPosts.map((post) => (
              <li key={post.id}>
                <Link to={`/posts/${post.id}`}>{post.titulo}</Link>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
