// src/components/PostCreate.js
import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { createPost } from '../api';
import { PostContext } from '../PostContext';

function PostCreate() {
  const navigate = useNavigate();
  const { addPost } = useContext(PostContext);

  const [post, setPost] = useState({
    nombre: '',
    titulo: '',
    descripcion: '',
    contenido: '',
    imagenes: ''
  });

  const handleChange = (e) => {
    setPost({ ...post, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newPost = await createPost(post);
    if (newPost && newPost.id) {
      addPost({ ...post, id: newPost.id, fecha: new Date().toISOString() });
      navigate(`/posts/${newPost.id}`);
    } else {
      alert('Error al crear el post');
    }
  };

  return (
    <div>
      <h2>Crear Nuevo Post</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Nombre:
          <input name="nombre" value={post.nombre} onChange={handleChange} required />
        </label>
        <label>
          Título:
          <input name="titulo" value={post.titulo} onChange={handleChange} required />
        </label>
        <label>
          Descripción:
          <textarea name="descripcion" value={post.descripcion} onChange={handleChange} />
        </label>
        <label>
          Contenido:
          <textarea name="contenido" value={post.contenido} onChange={handleChange} />
        </label>
        <label>
          Imágenes (URL):
          <input name="imagenes" value={post.imagenes} onChange={handleChange} />
        </label>
        <button type="submit">Crear Post</button>
      </form>
    </div>
  );
}

export default PostCreate;
