// src/api.js
const API_URL = 'https://post-back.searchdominio.online/api.php';

export async function getPosts() {
  const response = await fetch(`${API_URL}/posts`);
  return response.json();
}

export async function getPost(id) {
  const response = await fetch(`${API_URL}/posts/${id}`);
  return response.json();
}

// export async function createPost(data) {
//   const response = await fetch(`${API_URL}/posts`, {
//     method: 'POST',
//     headers: {
//       'Content-Type': 'application/json'
//     },
//     body: JSON.stringify(data)
//   });
//   return response.json();
// }

export async function updatePost(id, data) {
  const response = await fetch(`${API_URL}/posts/${id}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}

export async function deletePost(id) {
  const response = await fetch(`${API_URL}/posts/${id}`, {
    method: 'DELETE'
  });
  return response.json();
}
// src/api.js
// const API_URL = 'http://localhost:8000/api.php';

export async function createPost(data) {
  const response = await fetch(`${API_URL}/posts`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(data)
  });
  return response.json();
}
