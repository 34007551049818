// src/components/PostList.js
import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { PostContext } from '../PostContext';

function PostList() {
  const { posts } = useContext(PostContext);

  return (
    <div>
      <h2>Lista de Posts</h2>
      <ul className="post-list">
        {posts.map((post) => (
          <li key={post.id}>
            <Link to={`/posts/${post.id}`}>
              <h3>{post.titulo}</h3>
              <p>{post.descripcion}</p>
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

export default PostList;
