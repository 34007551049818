// src/PostContext.js
import React, { createContext, useState, useEffect } from 'react';
import { getPosts } from './api';

export const PostContext = createContext();

export function PostProvider({ children }) {
  const [posts, setPosts] = useState([]);

  useEffect(() => {
    refreshPosts();
  }, []);

  const refreshPosts = async () => {
    const data = await getPosts();
    setPosts(data);
  };

  const addPost = (post) => {
    setPosts([post, ...posts]);
  };

  const updatePostInState = (updatedPost) => {
    setPosts(posts.map((post) => (post.id === updatedPost.id ? updatedPost : post)));
  };

  const deletePostFromState = (id) => {
    setPosts(posts.filter((post) => post.id !== id));
  };

  return (
    <PostContext.Provider
      value={{ posts, refreshPosts, addPost, updatePostInState, deletePostFromState }}
    >
      {children}
    </PostContext.Provider>
  );
}
