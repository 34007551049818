// src/components/PostEdit.js
import React, { useState, useEffect, useContext } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getPost, updatePost } from '../api';
import { PostContext } from '../PostContext';

function PostEdit() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { updatePostInState } = useContext(PostContext);

  const [post, setPost] = useState({
    nombre: '',
    titulo: '',
    descripcion: '',
    contenido: '',
    imagenes: ''
  });

  useEffect(() => {
    async function fetchPost() {
      const data = await getPost(id);
      setPost(data);
    }
    fetchPost();
  }, [id]);

  const handleChange = (e) => {
    setPost({ ...post, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const updatedPost = await updatePost(id, post);
    updatePostInState(updatedPost);
    navigate(`/posts/${id}`);
  };

  return (
    <div>
      <h2>Editar Post</h2>
      <form onSubmit={handleSubmit}>
        <label>
          Nombre:
          <input name="nombre" value={post.nombre} onChange={handleChange} required />
        </label>
        <label>
          Título:
          <input name="titulo" value={post.titulo} onChange={handleChange} required />
        </label>
        <label>
          Descripción:
          <textarea name="descripcion" value={post.descripcion} onChange={handleChange} />
        </label>
        <label>
          Contenido:
          <textarea name="contenido" value={post.contenido} onChange={handleChange} />
        </label>
        <label>
          Imágenes (URL):
          <input name="imagenes" value={post.imagenes} onChange={handleChange} />
        </label>
        <button type="submit">Guardar Cambios</button>
      </form>
    </div>
  );
}

export default PostEdit;
